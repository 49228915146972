import { EncryptableData } from "encrypted";

export const mergeData = (
  model: EncryptableData,
  modelNew: EncryptableData
): void => {
  Object.keys(model).forEach(function(key) {
    if (typeof model[key] === "object") {
      if (!(model[key] instanceof Array))
        model[key] = { ...modelNew[key], ...model[key] };

      if (modelNew[key]) return mergeData(model[key], modelNew[key]);
    }
  });
};

export const hydrateData = (
  model: EncryptableData,
  newValue: any,
  ...keys: string[]
): void => {
  for (let i = 0; i < keys.length - 1; i += 1) {
    model = model[keys[i]];
  }

  if (model) model[keys[keys.length - 1]] = newValue;
};

export const runFncs = (
  model: EncryptableData,
  ...functions: ((model: EncryptableData) => void)[]
): void => {
  for (const f of functions) {
    f(model);
  }
};

export const isNewerVersion = (oldVer: string, newVer: string): boolean => {
  const oldParts = oldVer.split(".");
  const newParts = newVer.split(".");
  for (let i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i]; // parse int
    const b = ~~oldParts[i]; // parse int
    if (a > b) return true;
    if (a < b) return false;
  }
  return false;
};

export const runMigrationFncs = (
  model: EncryptableData,
  versionFncs: { [key: string]: (model: EncryptableData) => void }
): void => {
  for (const key in versionFncs) {
    if (isNewerVersion(model.version, key)) {
      versionFncs[key](model);
    }
  }
};

export const migrateData = (
  boardConfigOld: EncryptableData,
  migrationFncs: { [key: string]: (model: EncryptableData) => void },
  boardConfigNew?: EncryptableData // OPTIONLAL new config version model, with default values set
): EncryptableData => {
  const updatedConfig = { ...(boardConfigNew || {}), ...boardConfigOld };
  if (boardConfigNew) mergeData(updatedConfig, boardConfigNew);
  runMigrationFncs(updatedConfig, migrationFncs);
  return updatedConfig;
};
