<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-skeleton-loader type="image@2, divider@12"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6" class="pt-0">
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center mt-4">
      <v-col cols="12">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BoardEmpty"
};
</script>
