var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"div--board",style:({
    background: _vm.loaded
      ? _vm.config.background || _vm.backgroundColor
      : _vm.backgroundColor
  })},[_c('v-overlay',{attrs:{"z-index":9,"value":_vm.updateInProgress}},[_c('Progress',{attrs:{"color":"accent"}}),_c('h3',{staticClass:"accent--text"},[_vm._v(" "+_vm._s(_vm.$t("common.saving"))+" ")])],1),(
      !_vm.updateInProgress &&
        _vm.currentBoard &&
        _vm.currentBoard.confirmBeforeOpen &&
        !_vm.openBoard
    )?_c('BoardOverlay',{on:{"openBoard":function($event){_vm.openBoard = true}}}):(!_vm.updateInProgress && _vm.currentBoard && _vm.loaded && _vm.config.blocks)?_c('BoardTemplate',{attrs:{"config":_vm.config,"layout-component":_vm.layoutComponent,"layout-canvas-options":_vm.layoutCanvasOptions}}):_c('BoardEmpty'),_c('BaseModal',{attrs:{"loading":_vm.loadingLinkPass,"value":_vm.accessModal,"width":500,"close":false},on:{"update:value":function($event){_vm.accessModal=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("common.provide_password"))+" ")]),_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(_vm._s(_vm.boardName))])]},proxy:true}])},[(_vm.accessModal)?_c('BoardPassword',{on:{"onSubmit":_vm.initialBoardRequestAccess,"onCancel":function($event){_vm.accessModal = false}}}):_vm._e()],1),_c('EncryptionWrapper',{ref:"dlg",attrs:{"set":_vm.confirmPassword}},[(_vm.encModal)?_c('BoardEncryption',{attrs:{"confirm":_vm.confirmPassword}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }