<template>
  <v-container>
    <v-row justify="center">
      <v-overlay :z-index="0" opacity="1" color="gray lighten-3">
        <BaseButton
          color="primary"
          large
          class="btn--open"
          @click="$emit('openBoard')"
        >
          <span class="btn__content">{{ $t("board.open") }}</span>
          <span class="btn__loader">
            <v-progress-circular indeterminate :width="3" size="27" />
          </span>
        </BaseButton>
      </v-overlay>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BoardOverlay"
};
</script>

<style lang="scss" scoped>
::v-deep .btn--open {
  .btn__loader {
    visibility: hidden;
    position: absolute;
  }

  &:active {
    .btn__content {
      opacity: 0;
    }
    .btn__loader {
      visibility: visible;
    }
  }
}
</style>
