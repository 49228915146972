import { BlockConfig } from "@/options/blockOptions";
import { BoardConfig } from "@/options/boardOptions";
import { newDailyQuotes, QuotesBlockConfig } from "@/options/editorOptions";
import { hydrateData } from "@/plugins/migration";

// We can can still mutate config object, but NOT reassign a new value
// Last migration fnc MUST be equal to current board version
const personalBoardMigrations: {
  [key: string]: (model: BoardConfig) => void;
} = {
  "1": () => {
    // do nothing initial version
  },
  "2": (model: BoardConfig) => {
    // mergeData fnc will exec first automatically
    hydrateData(model, "2", "version");
  },
  "3": (model: BoardConfig) => {
    // mergeData fnc will exec first automatically
    // added new flag 'assignment' to Block config
    hydrateData(model, "3", "version");
  },
  "4": (model: BoardConfig) => {
    // mergeData fnc will exec first automatically
    // added new quotes
    hydrateData(model, "4", "version");
    const quoteBlock = model.blocks.find(
      (x: BlockConfig) => x.assignment === "daily_quotes"
    ) as QuotesBlockConfig;
    quoteBlock.quotes.push(...newDailyQuotes);
  }
};

const boardConfigMigration = () => {
  return { personalBoardMigrations };
};
export default boardConfigMigration();
