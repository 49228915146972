<template>
  <BaseModal v-model="dialog" :width="500" :close="false" persistent>
    <template v-slot:title>
      <v-card-title class="text-h5 font-weight-bold">
        {{ set ? $t("board.encrypt") : $t("common.provide_password") }}
        <Questionmark :tooltip="$t('questionmark.encrypt')" />
      </v-card-title>
      <v-card-subtitle class="pb-0">{{ boardName }}</v-card-subtitle>
    </template>
    <slot></slot>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EncryptionWrapper",
  props: {
    set: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null
  }),
  computed: {
    ...mapState("boards", ["currentBoard"]),
    boardName() {
      return this.currentBoard?.name;
    }
  },
  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree(e) {
      this.resolve(e);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  },
  provide: function() {
    return { agree: this.agree, cancel: this.cancel };
  }
};
</script>
