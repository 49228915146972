























































import Sentry from "@/plugins/sentry";
import Vue from "vue";
import mixins from "vue-typed-mixins";
import firebase from "firebase/app";
import { db } from "@/plugins/firebase";
import { mapState, mapGetters, mapActions, ActionPayload } from "vuex";
import { notificationStatus } from "@/options/notificationOptions";
import { debounce } from "lodash";
import {
  generateKey,
  encodeBase64,
  decodeBase64,
  decrypted,
  encrypted,
  EncryptableData
} from "encrypted";
import { migrateData, isNewerVersion } from "@/plugins/migration";
import { downloadData, uploadData } from "@/plugins/storage";
import EncryptionWrapper from "@/components/Boards/EncryptionWrapper.vue";
import BoardEmpty from "@/components/Boards/BoardEmpty.vue";
import BoardOverlay from "@/components/Boards/BoardOverlay.vue";
import BoardTemplate from "@/components/Boards/BoardTemplate.vue";
import {
  BoardConfig,
  NewPersonalBoardConfig,
  BoardConfigVersion,
  Colors
} from "@/options/boardOptions";
import boardConfigMigration from "@/migrations/boardConfigMigration";
import userMixin from "@/mixins/user";
import { uuid } from "vue-uuid";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import {
  overrideDefaultColors,
  overrideDefaultFontFamily
} from "@/helpers/vuetifyHelpers";
import { BACKGROUND } from "@/utils/color";
import { bootIntercom } from "@/plugins/intercom";

const NewPersonalBoard = Object.freeze(NewPersonalBoardConfig);

export default mixins(userMixin).extend({
  name: "Board",
  components: {
    BoardEmpty,
    BoardTemplate,
    BoardOverlay,
    EncryptionWrapper,
    BoardPassword: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Forms/Boards/BoardPasswordForm.vue"
      ),
    BoardEncryption: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Forms/Boards/BoardEncryptionForm.vue"
      )
  },
  data() {
    return {
      accessModal: false,
      encModal: false,
      boardStorageRef: null as firebase.storage.Reference | null,
      loaded: false,
      localChange: "",
      password: "",
      changed: false,
      config: {} as BoardConfig,
      unsubscribe: null as any,
      openBoard: false,
      confirmPassword: false,
      backgroundColor: BACKGROUND,
      loadingLinkPass: false,
      forceSave: false,
      layoutComponent: "",
      layoutCanvasOptions: undefined
    };
  },
  computed: {
    ...mapGetters("users", ["email", "uid"]),
    ...mapGetters("boards", ["getBoardStorageRef"]),
    ...mapState("boards", ["currentBoard", "updateInProgress"]),
    routerBoardId(): string {
      return this.$route.params.boardId;
    },
    boardName(): string {
      return this.currentBoard?.name;
    }
  },
  mounted() {
    overrideDefaultColors();
    overrideDefaultFontFamily();
    const identifiers = {
      //https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
      email: this.email,
      user_id: this.uid,
      board_id: this.routerBoardId
    };
    bootIntercom(identifiers);
  },
  watch: {
    routerBoardId: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.$store
            .dispatch(
              "boards/bindCurrentBoard",
              db.collection(`boards`).doc(val)
            )
            .catch(() => {
              this.notification({
                ...notificationStatus.ERROR,
                message: this.$t("board.not_found")
              });
              this.$router.push("/404");
            });
        }
      }
    },
    async "currentBoard.id"(val) {
      if (val) {
        this.loaded = false;
        this.openBoard = false;
        await this._loadBoard();
      }
    },
    async "currentBoard.change"(val) {
      if (val) {
        if (!this.localChange) {
          this.localChange = val;
        }
        if (val !== this.localChange) {
          await this._reFetchContent();
        }
      }
    },
    "config.colors"(colors: Colors) {
      if (colors) {
        overrideDefaultColors(colors);
      }
    },

    "config.fontFamily"(font: string) {
      if (font) {
        overrideDefaultFontFamily(font);
      }
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (
      this.forceSave &&
      this.owner &&
      from.params?.boardId &&
      to.params?.boardId !== from.params?.boardId
    ) {
      this.switchUpdateInProgress(true);
      await this._saveBoard();
      this.switchUpdateInProgress(false);
    }
    next();
  },
  async created() {
    window.addEventListener("beforeunload", this._preventCloseWindow);
    this.unsubscribe = this.$store.subscribeAction(
      async (action: ActionPayload): Promise<void> => {
        const path = action.type.split("/");
        if (path[0] === "boards" && path[1] === "encryptBoard") {
          await this._encryptBoard();
        }
        if (path[0] === "boards" && path[1] === "saveBoard") {
          this._saveBoard(action.payload);
        }
      }
    );
  },
  async beforeDestroy() {
    this.unsubscribe();
    window.removeEventListener("beforeunload", this._preventCloseWindow);
    await this.$store.dispatch("boards/unbindCurrentBoard");
  },
  methods: {
    ...mapActions("boards", [
      "switchUpdateInProgress",
      "switchCurrentBoardSaving"
    ]),
    ...mapActions("notifications", ["notification"]),
    initialBoardRequestAccess(password: string) {
      this.loadingLinkPass = true;
      this._fetchBoardUrl(password).then(async res => {
        if (!res.success) {
          this.notification({
            ...notificationStatus.ERROR,
            message: this.$t("common.password_incorrect")
          });
        } else {
          this.accessModal = false;
          this.password = password;
          await this._downloadBoard(res.url);
        }
        this.loadingLinkPass = false;
      });
    },
    _preventCloseWindow(e: any) {
      if (this.forceSave && this.owner) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    _fetchBoardUrl(password: string) {
      const url = `${process.env.VUE_APP_FIREBASE_FUNCTIONS_HOST}/boardAccess`;
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          id: this.currentBoard?.id,
          password: password
        })
      }).then(res => res.json());
    },
    _fetchBoardAccess() {
      const url = `${process.env.VUE_APP_FIREBASE_FUNCTIONS_HOST}/getBoardAccessMetadata`;
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          id: this.currentBoard?.id,
          email: this.email
        })
      }).then(res => res.json());
    },
    _reFetchContent: debounce(async function(this: any) {
      if (this.owner) {
        await this._downloadBoard();
      } else {
        return this._fetchBoardAccess().then(async (res: any) => {
          if (res.success) {
            if (res.url) {
              await this._downloadBoard(res.url);
            } else if (res.hasPassword && this.password) {
              const boardUrl = await this.fetchBoardUrl(this.password);
              await this._downloadBoard(boardUrl.url);
            }
          }
        });
      }
    }, 100),
    async _updateBoardChange() {
      if (this.currentBoard) {
        const change = `change_${uuid.v4()}`;
        this.localChange = change;
        await db
          .collection("boards")
          .doc(this.currentBoard.id)
          .update({
            change: change
          });
      }
    },
    _saveBoard: debounce(async function(
      this: any,
      options?: Record<string, any>
    ) {
      if (this.boardStorageRef && this.owner) {
        try {
          this.forceSave = true;
          this.switchCurrentBoardSaving(true);

          if (options) {
            this.config = Object.assign({}, this.config, options);
          }

          let config: {};
          const data = await downloadData(this.boardStorageRef);
          this.$store.dispatch("boards/bindBoardConfig", this.config);

          if (this.currentBoard?.encrypted || (data && "encryption" in data)) {
            const dataDocKey = await this._getEncryption();
            config = await encrypted(this.config, { key: dataDocKey });
          } else {
            config = this.config;
          }
          await uploadData(this.boardStorageRef, config, this.ownerId);
          await this._updateBoardChange();
        } catch (err) {
          Sentry.captureException(err);
        }
      } else {
        this.notification({
          ...notificationStatus.WARNING,
          message: this.$t("board.edition_not_allowed")
        });
      }
      this.forceSave = false;
      this.switchCurrentBoardSaving(false);
    },
    200),
    async _loadBoard() {
      try {
        await this.isOwner(this.currentBoard.id);
        if (this.owner) {
          await this._downloadBoard();
        } else {
          this._getBoardAccessTypes();
        }
      } catch {
        this._getBoardAccessTypes();
      }
    },
    _getBoardAccessTypes() {
      this._fetchBoardAccess().then(async res => {
        if (res.success) {
          if (res.hasPassword) {
            this.accessModal = true;
          } else {
            await this._downloadBoard(res.url);
          }
        } else {
          this.notification({
            ...notificationStatus.ERROR,
            message: this.$t("board.not_found")
          });
          this.$router.push("/404");
        }
      });
    },
    async _downloadBoard(url?: string) {
      if (this.currentBoard) {
        this.boardStorageRef = this.getBoardStorageRef(this.currentBoard.id);

        let config = {} as BoardConfig;
        if (this.boardStorageRef) {
          try {
            config = await downloadData(this.boardStorageRef, url);

            if ("encryption" in config) {
              const dataDocKey = await this._getEncryption();
              config = (await decrypted(JSON.parse(JSON.stringify(config)), {
                key: dataDocKey
              })) as BoardConfig;
            }

            if (this.currentBoard?.layoutId) {
              const template = await db
                .collection("layouts")
                .doc(this.currentBoard.layoutId)
                .get();
              const componentName = template.data()?.component;
              if (componentName) {
                this.layoutComponent = componentName;
              }
              const options = template.data()?.canvasOptions;
              if (options) {
                this.layoutCanvasOptions = options;
              }
            }

            if (
              config.version &&
              config.version !== BoardConfigVersion &&
              isNewerVersion(config.version, BoardConfigVersion)
            ) {
              this.config = migrateData(
                config,
                boardConfigMigration.personalBoardMigrations as {
                  [key: string]: (model: EncryptableData) => void;
                },
                NewPersonalBoard
              ) as BoardConfig;
              this.loaded = true;
              await this._saveBoard();
            } else {
              this.config = config;
              this.$store.dispatch("boards/bindBoardConfig", config);
              this.loaded = true;
            }
          } catch (e) {
            if (
              (e as firebase.storage.FirebaseStorageError)?.code ===
              "storage/object-not-found"
            ) {
              this.notification({
                ...notificationStatus.ERROR,
                message: this.$t("board.not_found")
              });
              this.$router.push("/404");
            } else {
              this.notification({
                ...notificationStatus.ERROR,
                message: this.$t("board.decrypt_error")
              });
              Sentry.captureException(e);
            }
          }
        }
      }
    },
    async _getEncryption() {
      this.encModal = true;
      let dataDocKey;
      if (this.$route.hash) {
        dataDocKey = decodeBase64(this.$route.hash.slice(1));
        return dataDocKey;
      }

      if (!dataDocKey) {
        const pass = await (this.$refs.dlg as Vue & {
          open: () => Promise<string>;
        })?.open();

        if (typeof pass === "string" && pass.length >= 3) {
          dataDocKey = await generateKey(pass, this.currentBoard.id);
          this.$router.push({
            hash: "#" + encodeBase64(dataDocKey)
          });

          this.confirmPassword = false;
          this.encModal = false;
          return dataDocKey;
        } else {
          this.confirmPassword = false;
          this.encModal = false;
          return;
        }
      }
    },
    async _encryptBoard() {
      if (this.boardStorageRef && this.owner) {
        const data = await downloadData(this.boardStorageRef);
        if (data && "encryption" in data) {
          this.notification({
            ...notificationStatus.WARNING,
            message: this.$t("board.already_encrypted")
          });
        } else {
          this.forceSave = true;
          this.switchCurrentBoardSaving(true);
          this.confirmPassword = true;

          const dataDocKey = await this._getEncryption();
          if (dataDocKey) {
            this.switchUpdateInProgress(true);
            const config = await encrypted(this.config, { key: dataDocKey });

            await uploadData(this.boardStorageRef, config, this.ownerId);
            await db
              .collection("boards")
              .doc(this.currentBoard.id)
              .update({ encrypted: true });

            analyticsLogEvent(gaEventNames.board_encrypted, {
              board_id: this.currentBoard.id,
              board_user_id: this.uid
            });

            this.notification({
              ...notificationStatus.SUCCESS,
              message: this.$t("board.encrypted")
            });
            this.switchUpdateInProgress(false);
          }
          this.forceSave = false;
          this.switchCurrentBoardSaving(false);
        }
      }
    }
  }
});
