
























import Vue from "vue";
import { BoardConfig } from "@/options/boardOptions";
import ImagesTemplate from "@/components/Images/ImagesTemplate.vue";
const EditorTemplate = (templateName: string) =>
  import(`@/components/Editors/EditorTemplates/${templateName}.vue`);

export default Vue.extend({
  name: "BoardTemplate",
  components: {
    ImagesTemplate,
    EditorWrapper: () =>
      import(/* webpackPrefetch: true */ "@/components/Editors/EditorWrapper")
  },
  props: {
    config: {
      type: Object,
      default: () => ({} as BoardConfig)
    },
    layoutComponent: {
      type: String,
      required: true
    },
    layoutCanvasOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      templateComponent: () =>
        EditorTemplate(this.layoutComponent || "EditorTemplateDefault")
    };
  }
});
